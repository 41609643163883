import { Mixpanel } from 'mixpanel-browser';
import { IReportingConfig } from './types';
import { initGa, loadGa } from './snippets/gtag.snippet';
import { initMixpanel, loadMixpanel } from './snippets/mixpanel.snippet';
import { initFb, loadFb } from './snippets/fb.snippet';

declare global {
  interface Window {
    promoConfig: Promise<IReportingConfig>;
    promoReporting: Promise<PromoReporting>;
    cookieBackup?: string;
    mixpanel: Mixpanel;
    analitycDebug: {
      scheduled: any[];
      sent: any[];
    };
    dataLayer: any[];
  }
}

enum STORAGE_KEYS {
  FIRST_IMPRESSION_UTM = 'firstImpressionsUTMs',
  HAD_FIRST_VISIT = 'hadFirstVisit',
  IS_FIRST_VISIT = 'isFirstVisit',
  ALREADY_REGISTERED = 'alreadyRegistered',
  REGISTERED_IN_THIS_SESSION = 'registeredInThisSession',
  SESSION_DATA_KEY = 'sessionData',
  COOKIE_POLICY = 'cookie_policy',
}

enum REFERRER_TYPE {
  DIRECT = 'organicdirect',
  INTERNAL = 'organicinternaldirect',
  GOOGLE = 'organicgoogle',
  BING = 'organicbing',
  YOUTUBE = 'organicyoutube',
  SOCIAL = 'organicsocial',
  ORGANIC = 'organicreferrer',
}

const REFERRER_HOSTS_BY_TYPE: { [key in REFERRER_TYPE]: string[] } = {
  [REFERRER_TYPE.DIRECT]: [],
  [REFERRER_TYPE.INTERNAL]: ['slide.ly'],
  [REFERRER_TYPE.GOOGLE]: ['google'],
  [REFERRER_TYPE.BING]: ['bing'],
  [REFERRER_TYPE.YOUTUBE]: ['youtube'],
  [REFERRER_TYPE.SOCIAL]: ['facebook', 'twitter', 't.co', 'linkedin', 'instagram'],
  [REFERRER_TYPE.ORGANIC]: [],
};

const QUERY_PARAM_BY_UTM = {
  utm_source: 'source',
  source: 'source',
  utm_campaign: 'campaign',
  utm_camp_id: 'camp_id',
  utm_content: 'clickedOn',
  utm_medium: 'utm_medium',
  utm_term: 'utm_term',
  utm_term_id: 'utm_term_id',
  utm_creative: 'utm_creative',
  utm_creative_id: 'utm_creative_id',
  utm_kw: 'utm_kw',
  utm_extension_id: 'utm_extension_id',
};

interface IQueueElement {
  type: string;
  args: any[];
}

class PromoReporting {
  private defaultParams = {};

  private ezReportsQueue: IQueueElement[] = [];

  private isReportingDisabledByGdpr = true;

  async init() {
    (await window.slidelySdk).init({
      appId: PromoReporting.getSdkClientIdByEnv(),
    });

    if (PromoReporting.isDebugEnabled()) {
      window.analitycDebug = {
        scheduled: [],
        sent: [],
      };
    }

    await this.initGlobalParams();
    this.isReportingDisabledByGdpr = !localStorage.getItem(STORAGE_KEYS.COOKIE_POLICY);

    await loadMixpanel();
    await loadGa();
    loadFb();

    if (this.isReportingDisabledByGdpr) {
      await this.initReportingDisabledByGdprFromSessionData();
    } else {
      this.enableReporting();
    }

    return this;
  }

  static getSdkClientIdByEnv() {
    return window.location.origin.indexOf('local') !== -1 ? 'test' : '53luithergkht536klgjerlgd135231042';
  }

  async initGlobalParams() {
    const queryParams: {
      [key: string]: string;
    } = {
      asset: (await window.promoConfig).asset,
    };

    let queryString = window.location.search;
    if (queryString) {
      queryString = queryString.substring(1);
      const queryParts = queryString.split('&');

      queryParts.forEach((part) => {
        const keyVal = part.split('=');

        if (!keyVal.length) {
          return;
        }

        const [key, value] = keyVal;
        const utms = Object.keys(QUERY_PARAM_BY_UTM);

        if (utms.includes(key)) {
          queryParams[QUERY_PARAM_BY_UTM[key]] = value;
        }
      });

      if (localStorage.getItem(STORAGE_KEYS.HAD_FIRST_VISIT)) {
        localStorage.setItem(STORAGE_KEYS.HAD_FIRST_VISIT, 'true');
        sessionStorage.setItem(STORAGE_KEYS.IS_FIRST_VISIT, 'true');
      }

      if (!(await window.slidelySdk).getLoggedIn() && !localStorage.getItem(STORAGE_KEYS.FIRST_IMPRESSION_UTM)) {
        const firstImpressionsUTMs = {
          firstVisit_source: queryParams.source,
          firstVisit_campaign: queryParams.campaign,
          firstVisit_media: queryParams.utm_medium,
          firstVisit_term: queryParams.utm_term,
          firstVisit_creative: queryParams.utm_creative,
          firstVisit_asset: (await window.promoConfig).asset,
          firstVisit_camp_id: queryParams.camp_id,
          firstVisit_kw: queryParams.utm_kw,
          firstVisit_term_id: queryParams.utm_term_id,
          firstVisit_creative_id: queryParams.utm_creative_id,
          firstVisit_extension_id: queryParams.utm_extension_id,
        };

        localStorage.setItem(STORAGE_KEYS.FIRST_IMPRESSION_UTM, JSON.stringify(firstImpressionsUTMs));
      }
    }

    this.mergeGlobalParams(queryParams);
  }

  mergeGlobalParams(paramsPassed: { [key: string]: string | null }) {
    let params = { ...paramsPassed };
    let dataFromCookie = PromoReporting.getCookieData();
    if (dataFromCookie) {
      const dataFromCookieObject = JSON.parse(dataFromCookie);

      params = Object.assign(dataFromCookieObject, params);
    } else {
      dataFromCookie = PromoReporting.getCookieData('ezRI');
      if (dataFromCookie) {
        const dataFromCookieObject = JSON.parse(dataFromCookie);

        params = Object.assign(dataFromCookieObject, params);
      }
    }

    if (!params.source) {
      params.source = PromoReporting.getOrganicTrafficSource();
    } else {
      params.source.replace(/ /g, ''); // remove space
    }

    this.defaultParams = Object.assign(this.defaultParams, params);

    const saveToCookie: { [key: string]: string | null } = { ...this.defaultParams };
    delete saveToCookie.clickedOn;

    this.setCookie('ezExtRI', JSON.stringify(saveToCookie), 28);

    return this.defaultParams;
  }

  static getOrganicTrafficSource() {
    if (!document.referrer) {
      return REFERRER_TYPE.DIRECT;
    }

    const host = new URL(document.referrer).hostname;

    if (host.includes('promo.com')) return null;

    const [type] =
      Object.entries(REFERRER_HOSTS_BY_TYPE).find(([, referrerHosts]) =>
        referrerHosts.find((referrerHost) => host.indexOf(referrerHost) >= 0),
      ) || [];

    if (type) return type;

    return REFERRER_TYPE.ORGANIC;
  }

  static getCookieData(cookieName = 'ezExtRI') {
    const cookiesArray = (window.cookieBackup || document.cookie).split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
      const key = cookiesArray[i].substr(0, cookiesArray[i].indexOf('=')).replace(/^\s+|\s+$/g, '');
      if (key === cookieName) {
        return unescape(cookiesArray[i].substr(cookiesArray[i].indexOf('=') + 1));
      }
    }

    return null;
  }

  setCookie(name: string, value: string, expireDays: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + expireDays * 86400000); // 86400000 === 24 * 60 * 60 * 1000
    const cookieValue = escape(value) + (expireDays == null ? '' : `; expires=${expires.toUTCString()}`);
    document.cookie = `${name}=${cookieValue}; path=/`;
    return this;
  }

  trackEvent(eventName: string, eventData: any) {
    this.mixpanelReport(eventName, eventData);
    this.googleAnalyticsReport(eventName);
  }

  trackPageView(pageName: string, eventData: any) {
    const eventName = `${pageName} viewed`;

    this.mixpanelReport(eventName, eventData);
    this.googleAnalyticsReport(eventName, { nonInteraction: true });
  }

  googleAnalyticsReport(eventName: string, options: { [key: string]: any } = {}) {
    if (!window.gtag) {
      return;
    }

    if (this.isReportingDisabledByGdpr) {
      this.queueReport('googleAnalytics', [eventName, options]);
      return;
    }

    const gaCategory = options.category || 'default';
    const eventData = {
      non_interaction: options.nonInteraction,
      event_category: gaCategory,
      event_label: eventName,
    };

    window.gtag('event', eventName, eventData);

    if (PromoReporting.isDebugEnabled()) {
      console.log('[web: google] reported', eventName, options);

      window.analitycDebug.sent.push({
        service: 'google',
        eventName,
        eventData,
      });
    }
  }

  mixpanelReport(eventName: string, eventDataPassed: { [key: string]: any } = {}) {
    if (this.isReportingDisabledByGdpr) {
      this.queueReport('mixpanel', [eventName, eventDataPassed]);
      return;
    }

    // clone defaults to prevent assign from overiding it
    const copyOfDefaults = JSON.parse(JSON.stringify(this.defaultParams));

    const eventData = Object.assign(copyOfDefaults, eventDataPassed);

    eventData.isAlreadyRegistered = !!localStorage.getItem(STORAGE_KEYS.ALREADY_REGISTERED); // set in session.js, see load, loadData and loadDataFromServer
    eventData.isFirstSession = !!sessionStorage.getItem(STORAGE_KEYS.IS_FIRST_VISIT); // set on first visit when reports.js is initiated

    // this is the not the first session if the user is already registered and the user did not register in this session
    if (eventData.isFirstSession && eventData.isAlreadyRegistered && !PromoReporting.hasRegisteredInThisSession()) {
      eventData.isFirstSession = false;
    }

    window.mixpanel.track(eventName, eventData);

    if (PromoReporting.isDebugEnabled()) {
      console.log('[web: mixpanel] reported', eventName, eventData);

      window.analitycDebug.sent.push({
        service: 'mixpanel',
        eventName,
        eventData,
      });
    }
  }

  static setPeopleProperties(properties: { [key: string]: any }) {
    window.mixpanel.people.set(properties);
  }

  queueReport(type: string, args: any[]) {
    this.ezReportsQueue.push({ type, args });

    if (PromoReporting.isDebugEnabled()) {
      console.log('[web] scheduled', type, args);

      window.analitycDebug.scheduled.push({
        type,
        args,
      });
    }
  }

  enableReporting() {
    this.isReportingDisabledByGdpr = false;
    initMixpanel();
    initGa();
    initFb();
    this.flushReportsQueue();
  }

  onAcceptCookiePolicy() {
    window.localStorage.setItem(STORAGE_KEYS.COOKIE_POLICY, '1');
    this.enableReporting();
  }

  flushReportsQueue() {
    this.ezReportsQueue.forEach((reportData) => {
      switch (reportData.type) {
        case 'mixpanel':
          // eslint-disable-next-line
          // @ts-ignore
          this.mixpanelReport(...reportData.args);
          break;
        case 'googleAnalytics':
          // eslint-disable-next-line
          // @ts-ignore
          this.googleAnalyticsReport(...reportData.args);
          break;
        default:
      }
    });

    // this is a safe way to clear an array in js
    this.ezReportsQueue.length = 0;
  }

  async initReportingDisabledByGdprFromSessionData() {
    const response = await (await fetch('/promoVideos/config/get-promo-next-page-config')).json();

    if (!response.response.success) {
      return;
    }

    if (this.isReportingDisabledByGdpr && !response.response.body.reporting.isReportingDisabledByGdpr) {
      this.enableReporting();
    }

    sessionStorage.setItem(STORAGE_KEYS.SESSION_DATA_KEY, JSON.stringify(response));
  }

  static hasRegisteredInThisSession() {
    // 'ez_prm_ugr' cookie is set on the server on registration
    // REGISTERED_IN_THIS_SESSION is set in reports.js, see reportUserRegistration
    return (
      PromoReporting.getCookieData('ez_prm_ugr') === '1' ||
      !!sessionStorage.getItem(STORAGE_KEYS.REGISTERED_IN_THIS_SESSION)
    );
  }

  static isDebugEnabled() {
    return PromoReporting.getCookieData('analyticDebug') !== null;
  }
}

window.promoConfig.then(() => {
  const promoReporting = new PromoReporting();

  // eslint-disable-next-line
  // @ts-ignore
  window.resolvePromoReporting(promoReporting.init());
});
