import appendScript from '../helpers/append-script';

const loadGa = async () => {
  const { google } = (await window.promoConfig).reporting.analytics;
  appendScript(`https://www.googletagmanager.com/gtag/js?id=${google.accountId}`);

  window[`ga-disable-${google.accountId}`] = true;

  if (google.adwordsAccountId) {
    window[`ga-disable-${google.adwordsAccountId}`] = true;
  }

  if (google.newAdwordsAccountId) {
    window[`ga-disable-${google.newAdwordsAccountId}`] = true;
  }

  window.dataLayer = window.dataLayer || [];

  function gtag(...args: any[]) {
    window.dataLayer.push(args);
  }
  gtag('js', new Date());

  window.gtag = gtag;
};

const initGa = async () => {
  const { google } = (await window.promoConfig).reporting.analytics;
  window[`ga-disable-${google.accountId}`] = false;
  window.gtag('config', google.accountId);

  if (google.adwordsAccountId) {
    window[`ga-disable-${google.adwordsAccountId}`] = false;
    window.gtag('config', google.adwordsAccountId);
  }

  if (google.newAdwordsAccountId) {
    window[`ga-disable-${google.newAdwordsAccountId}`] = false;
    window.gtag('config', google.newAdwordsAccountId);
  }
};

export { loadGa, initGa };
